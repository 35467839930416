/* src/components/Header.css */

header {
    background-color: var(--brand-light-gray);
    color: var(--brand-light-gray);
    padding: 10px;
  }
  
  .nav-link {
    color: var(--brand-blue);
    text-decoration: none;
    margin: 0 10px;
  }
  
  .nav-link:hover {
    color: var(--brand-blue);
  }
  