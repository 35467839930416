/* src/pages/Signup.css */

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--brand-light-gray);
  }
  
  .signup-card {
    width: 100%;
    max-width: 400px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 2rem;
  }
  
  .card-title {
    color: var(--brand-blue);
  }
  
  .btn-primary {
    background-color: var(--brand-blue);
    border-color: var(--brand-blue);
  }
  
  .btn-primary:hover {
    background-color: var(--brand-black);
    border-color: var(--brand-black);
  }
  
  .btn-danger {
    background-color: #db4437;
    border-color: #db4437;
  }
  
  .btn-danger:hover {
    background-color: #c23321;
    border-color: #c23321;
  }
  